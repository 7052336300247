@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-prev {
    left: 3% !important;
    z-index: 1;
}

.slick-next {
    right: 3% !important;
    z-index: 1;
}

#slider-container .slider {
    overflow: initial;
}